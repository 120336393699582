const CLIENT_ID = process.env.REACT_APP_HCA_CLIENT_ID;
const SCOPES = process.env.REACT_APP_HCA_SCOPES;

const HCA_LOAD_SDK = "loadHcaSdkScript";
const HCA_SDK_CONFIG = "hcaSdkConfigScript";
const HCA_LOGOUT = "logoutHcaScript";

export function setUpHcaSdk() {
  const loadHcaSdkScriptElement = document.getElementById(HCA_LOAD_SDK);
  const hcaSdkConfigScriptElement = document.getElementById(HCA_SDK_CONFIG);
  if (loadHcaSdkScriptElement && hcaSdkConfigScriptElement) {
    loadHcaSdkScriptElement.remove();
    hcaSdkConfigScriptElement.remove();
  }

  const loadHcaSdkScript = document.createElement("script");
  const hcaSdkConfigScript = document.createElement("script");

  loadHcaSdkScript.setAttribute("type", "text/javascript");
  loadHcaSdkScript.id = HCA_LOAD_SDK;
  hcaSdkConfigScript.setAttribute("type", "text/javascript");
  hcaSdkConfigScript.id = HCA_SDK_CONFIG;

  // pull in HCA SDK
  loadHcaSdkScript.src = "https://static.healthcaresdks.com/hca/v1/hca-sdk.js";
  document.body.appendChild(loadHcaSdkScript);

  hcaSdkConfigScript.appendChild(
    document.createTextNode(`
    hcaSdk.setHcaSdkConfig(
      "${CLIENT_ID}",
      ${SCOPES}
    );
          
    hcaSdk.setLoginCallBack((response) => {
      const inputElement = document.getElementById("login_input");
      inputElement?.setAttribute("login_context", JSON.stringify(response));
      document.getElementById("login_button")?.click();
    });
  `)
  );

  // after HCA SDK loaded
  // initialize HCA SDK
  loadHcaSdkScript.onload = () => {
    document.body.appendChild(hcaSdkConfigScript);
  };
}

export function logoutHca() {
  const logoutHcElement = document.getElementById(HCA_LOGOUT);
  if (logoutHcElement) {
    logoutHcElement.remove();
  }
  const logoutHcaScript = document.createElement("script");
  logoutHcaScript.setAttribute("type", "text/javascript");
  logoutHcaScript.id = HCA_LOGOUT;

  const scriptText = document.createTextNode(`
    if (hcaSdk.isAccountLogged()) {
      hcaSdk.signOut();
    }
  `);

  logoutHcaScript.appendChild(scriptText);
  document.body.appendChild(logoutHcaScript)
}
