import React, { Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import CookieSection from "./component/Cookies/Cookies";
import Navbar from "./component/Nav/Nav";
import ScrollToTop from "./component/ScrollToTop";
import WithLoader from "./component/WithLoader";
import { getUserData } from "./context/AuthContext";
import { PageType } from "./interfaces/Pages";
import { Routes } from "./routing/Routes";
import { getConditions, getCurrentUser, login, LOGIN } from "./store/AuthActions";
import { getContent } from "./store/PageContentActions";
import { authState } from "./store/Reducer";
import { finishedLoading, loading, TOOLBOX_FETCH_REQUESTED } from "./store/ToolBoxActions";
import ReactGA from "react-ga";
import "./i18n/config";
import { setUpHcaSdk } from "./resources/scripts/HcaScript";
import { getAccessToken, getUserEmail } from "./context/HcaAuthContext";
import { ATTEMPT_REGISTER_HCP } from "./constants/constants";
import { Api } from "./api/Api";

const App = () => {
  const auth = useSelector(authState);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(!localStorage.getItem("cookie_accept"));

  const submitCookie = () => {
    localStorage.setItem("cookie_accept", "true");

    const windowGlobal = (window as any)
  
    windowGlobal.gtag('config', 'G-ZQWQ8LH72D');

    setOpen(false);
  };

  useEffect(() => {
    // initialize HCA SDK and config
    setUpHcaSdk();

    ReactGA.initialize("G-ZQWQ8LH72D");
    ReactGA.pageview(window.location.pathname + window.location.search);

    const userData = getUserData();
    if (userData.access_token && auth.authenticated === false) {
      dispatch(getContent(PageType.profile));
      dispatch({ type: LOGIN, userData: userData });
      dispatch({ type: TOOLBOX_FETCH_REQUESTED });
    }

    dispatch(getContent(PageType.homeOld));
    dispatch(getConditions());
    dispatch(getCurrentUser());
    dispatch(getContent(PageType.popup));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleHcaLogin() {
    const login_context = document.getElementById("login_input")?.getAttribute("login_context");
    if (login_context) {
      const hcaResponse = JSON.parse(login_context);
      const token = getAccessToken(hcaResponse);
      if (token) {
        dispatch(loading(ATTEMPT_REGISTER_HCP));

        Api.registerHCP({ token: token }).then(
          (result) => {
            dispatch(finishedLoading(ATTEMPT_REGISTER_HCP, result.message, true));
            dispatch(login({ ...JSON.parse(result.data), email: getUserEmail(hcaResponse) }));
            dispatch(getContent(PageType.profile));
            dispatch({ type: TOOLBOX_FETCH_REQUESTED });
            dispatch(getCurrentUser());
          },
          (error) => {
            dispatch(finishedLoading(ATTEMPT_REGISTER_HCP, error.message, false));
          }
        );
      }
    }
  }

  return (
    <Suspense fallback={<div></div>}>
      <Router>
        <div style={{display: 'none'}}>
          <div id="hca_signup"></div>
          <div id="hca_signin"></div>
          <input id="login_input"/>
          <button onClick={handleHcaLogin} id="login_button"></button>
        </div>
        <ScrollToTop />
        <div className={"page-container"}>
          <Navbar />
          <div className={"content-wrap"}>
            <WithLoader>
              <CookieSection
                open={open}
                onSubmit={submitCookie}
                toggleDrawer={(open) => setOpen(open)}
              />
              <Routes />
            </WithLoader>
          </div>
        </div>
      </Router>
    </Suspense>
  );
};

export default App;
