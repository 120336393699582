import { Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Api } from "../../api/Api";
import "../../assets/scss/Login/Login.scss";
import Footer from "../../component/Footer/Footer";
import { ATTEMPT_LOGIN } from "../../constants/constants";
import { GAEvent, GAEventCategory } from "../../interfaces/GAEvent";
import { PageType } from "../../interfaces/Pages";
import LoginSideImageGeneral from "../../resources/images/auth/login_side_image.jpg";
import LoginSideImagePatient from "../../resources/images/auth/login_side_image_patient.jpg";
import LoginSideImageHCP from "../../resources/images/auth/login_side_image_hcp.jpg";
import { getCurrentUser, login, logout } from "../../store/AuthActions";
import { getContent } from "../../store/PageContentActions";
import { authState } from "../../store/Reducer";
import {
  finishedLoading,
  loading,
  TOOLBOX_FETCH_REQUESTED,
  TOOLBOX_RESPONSE_UPDATE,
} from "../../store/ToolBoxActions";
import { createGAEvent } from "../../util/util";
import LoginForm from "./LoginForm";

const Login = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { userType } = useParams();
  const auth = useSelector(authState);

  let loginSideImage = LoginSideImageGeneral;

  if (userType === "patient") {
    loginSideImage = LoginSideImagePatient;
  } else if (userType === "hcp") {
    loginSideImage = LoginSideImageHCP;
  }

  useEffect(() => {
    dispatch(getContent(PageType.login));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (auth?.authenticated && !userType) {
    history.push("/" + PageType.home);
  }

  const onLogin = async (data: any) => {
    dispatch(loading(ATTEMPT_LOGIN));

    createGAEvent(GAEvent.FORM_SUBMISSION, GAEventCategory.LOGIN);

    if (!data.field_remember_me) {
      window.addEventListener("beforeunload", function (e) {
        e.preventDefault();

        if (PerformanceNavigationTiming?.prototype?.type === "reload") {
          return;
        }

        sessionStorage.clear();
        localStorage.clear();
        dispatch(logout(""));
      });
    }

    if (auth?.authenticated) {
      await Api.logout().catch((error) => {});
    }

    Api.login(data).then(
      (result) => {
        dispatch(finishedLoading(ATTEMPT_LOGIN, result, true));
        dispatch(login({ ...result, email: data.name }));
        dispatch(getContent(PageType.profile));
        dispatch({ type: TOOLBOX_FETCH_REQUESTED });
        dispatch(getCurrentUser());
        history.push("/");
      },
      (error) => {
        dispatch(finishedLoading(ATTEMPT_LOGIN, error.message, false));
        dispatch({ type: TOOLBOX_RESPONSE_UPDATE, error: error.message });
      }
    );
  };

  return (
    <>
      <Grid container spacing={0} className="login-page">
        <Grid item md={12} lg={5} className="login-form">
          <LoginForm onSubmit={onLogin} />
          <div className="login-image-container">
            <img src={loginSideImage} alt="" />
          </div>
        </Grid>
      </Grid>
      <Footer />
    </>
  );
};

export default Login;
