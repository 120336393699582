import { Box } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import "../../assets/scss/Register/HCP/HCP.scss";
import Footer from "../../component/Footer/Footer";
import WithLoader from "../../component/WithLoader";
import { PageType } from "../../interfaces/Pages";
import { contentState, loadingState } from "../../store/Reducer";

export default function HCPRegisterForm() {
  const content = useSelector(contentState);
  const { t } = useTranslation();
  const loadingPage = useSelector(loadingState).loading.includes(
    PageType.registerHCP
  );

  return (
    <WithLoader controlLoading loading={loadingPage}>
      <Container component="main" className={"register-page hcp-register-page"}>
        <div className="register-form register-hcp-form">
          <div className="register-form-inner-container">
            <Box>
              <Typography component="h1" variant="h1" className="main-title">
                {t("register.form.hcp.pageTitle")}
              </Typography>
            </Box>
            <Box className="register-form-items">
            </Box>
          </div>
        </div>
      </Container>
      <Footer legal_number={content?.["register-hcp"]?.legal_number} />
    </WithLoader>
  );
}
