export interface HcaAuthContext {
  homeAccountId: string,
  environment: string,
  tenantId: string,
  username: string,
  localAccountId: string,
  idTokenClaims: {
    ver: string,
    iss: string,
    sub: string,
    aud: string,
    exp: number,
    acr: string,
    nonce: string,
    iat: number,
    auth_time: number,
    scope: string,
    email: string,
    idp: string,
    userId: string,
    displayName: string,
    family_name: string,
    trustLevel: number,
    tid: string,
    at_hash: string,
    nbf: number
  }
}

export function getAccessToken(hcaResponse: HcaAuthContext) {
  const item = localStorage.getItem(`${hcaResponse.homeAccountId}-${hcaResponse.environment}-accesstoken-${hcaResponse.idTokenClaims.aud}-${hcaResponse.tenantId}-${hcaResponse.idTokenClaims.scope.split(" ")[0]}--`);
  if (item) {
    return JSON.parse(item).secret;
  }
}

export function getUserEmail(hcaResponse: HcaAuthContext): string {
  return  hcaResponse.idTokenClaims.email;
}
